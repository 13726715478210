import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en'
import de from './locales/de'
import fr from './locales/fr'
import pt from './locales/pt'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    de,
    fr,
    pt,
  },
})
