import ptLocale from 'element-ui/lib/locale/lang/pt'

export default {
  Dashboard: 'Dashboard',
  Analytics: 'Analytics',
  Business: 'Business',
  Method: 'Method',
  Channel: 'Channel',
  Transactions: 'Transações',
  List: 'Lista',
  Refund: 'Refund',
  Merchants: 'Comerciantes',
  Commercial: 'Comercial',
  Channels: 'Canais',
  Management: 'Management',
  Promotions: 'Promoções',
  Financial: 'Financeiro',
  Payments: 'Pagamentos',
  Download: 'Baixar',
  BankBills: 'Banco Bills',
  Query: 'Consulta',
  AntiFraud: 'Anti-Fraude',
  Anonymous: 'Anônimo',
  Sanctions: 'Sanctions',
  Development: 'Development',
  Calendar: 'Calendar',
  V1Import: 'V1 Import',
  Backdoor: 'Backdoor',
  Settlement: 'Settlement',
  Manual: 'Manual',
  Report: 'Report',
  Invoice: 'Invoice',
  Payout: 'Payout',
  PayoutDownload: 'Download',
  Switcher: 'Switcher',
  Users: 'Users',
  Edit: 'Edit',
  Admin: 'Admin',
  AntiMoneyLaundry: 'AML',
  Review: 'Revisão',
  Rules: 'Rules',
  Recurring: 'Recurring',
  TongDun: 'TongDun',
  Policy: 'Policy',
  Invoke: 'Invoke',
  RiskTrend: 'Risk Trend',
  IdManagement: 'Gestão de Identidadet',
  Blacklist: 'Lista negra',
  RuleManagement: 'Gestão de regras',
  AmlRuleManagement: 'Gestão de regras',
  Group: 'Grupo',
  KYC: 'KYC',
  APP: 'APP',
  Training: 'Formação',
  Waybill: 'Waybill',
  Upload: 'Upload',
  PayoutAccount: 'Payout Account',
  Registration: 'Registration',
  Reseller: 'Reseller',
  ResellerUsers: 'Resellers',
  ResellerManagement: 'Reseller Management',
  ResellerKyc: 'KYC Management',
  VirtualAccount: 'Virtual Account',
  ...ptLocale,
}
