import Vue from 'vue'
import router from '@/router'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: process.env.VUE_APP_BASE_SERVER,
  headers: {},
})

// Request Interceptor
axiosIns.interceptors.request.use(
  config => {
    // Get token from localStorage
    const accessToken = localStorage.getItem('accessToken')

    // If token is present add it to request's Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.access_token = accessToken
    }
    return config
  },
  error => Promise.reject(error),
)

// Add request/response interceptor
axiosIns.interceptors.response.use(
  response => response,
  error => {
    // const { config, response: { status } } = error
    const { response } = error

    if (response.status === 401) {
      router.replace({
        path: '/login',
      })
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
