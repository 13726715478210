import enLocale from 'element-ui/lib/locale/lang/en'

export default {
  Dashboard: 'Dashboard',
  Analytics: 'Analytics',
  Business: 'Business',
  Method: 'Method',
  Channel: 'Channel',
  Transactions: 'Transactions',
  List: 'List',
  Refund: 'Refund',
  Merchants: 'Merchants',
  Commercial: 'Commercial',
  Channels: 'Channels',
  Management: 'Management',
  Promotions: 'Promotions',
  Financial: 'Financial',
  Payments: 'Payments',
  Download: 'Download',
  BankBills: 'Bank Bills',
  Query: 'Query',
  AntiFraud: 'Anti-Fraud',
  Anonymous: 'Anonymous',
  Sanctions: 'Sanctions',
  Development: 'Development',
  Calendar: 'Calendar',
  V1Import: 'V1 Import',
  Backdoor: 'Backdoor',
  Settlement: 'Settlement',
  Manual: 'Manual',
  Report: 'Report',
  Invoice: 'Invoice',
  Payout: 'Payout',
  PayoutDownload: 'Download',
  Switcher: 'Switcher',
  Users: 'Users',
  Edit: 'Edit',
  Admin: 'Admin',
  AntiMoneyLaundry: 'AML',
  Review: 'Review',
  Rules: 'Rules',
  Recurring: 'Recurring',
  IdManagement: 'ID Management',
  Blacklist: 'Blacklist',
  RuleManagement: 'Rule Management',
  AmlRuleManagement: 'Rule Management',
  Group: 'Group',
  TongDun: 'TongDun',
  Policy: 'Policy',
  Invoke: 'Invoke',
  RiskTrend: 'Risk Trend',
  KYC: 'KYC',
  APP: 'APP',
  Training: 'Training',
  Waybill: 'Waybill',
  Upload: 'Upload',
  PayoutAccount: 'Payout Account',
  Registration: 'Registration',
  Reseller: 'Reseller',
  ResellerUsers: 'Resellers',
  ResellerManagement: 'Reseller Management',
  ResellerKyc: 'KYC Management',
  VirtualAccount: 'Virtual Account',
  ...enLocale,
}
