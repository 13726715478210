export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/pagsmile/other/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/pagsmile/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/pagsmile/authentication/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },

  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/pagsmile/user/Profile.vue'),
    meta: {
      resource: 'Dashboard',
    },
  },
  // dashboard
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/pagsmile/other/ComingSoon.vue'),
    meta: {
      resource: 'Dashboard',
    },
  },
  {
    path: '/analytics/method',
    name: 'analytics-method',
    component: () => import('@/pagsmile/analytics/MethodAnalytics.vue'),
    meta: {
      resource: 'Analytics.Method',
    },
  },
  {
    path: '/analytics/payment',
    name: 'analytics-payment',
    component: () => import('@/pagsmile/analytics/PaymentAnalytics.vue'),
    meta: {
      resource: 'Analytics.Payment',
    },
  },
  {
    path: '/analytics/channel',
    name: 'analytics-channel',
    component: () => import('@/pagsmile/analytics/ChannelAnalytics.vue'),
    meta: {
      resource: 'Analytics.Channel',
    },
  },
  {
    path: '/transactions/list',
    name: 'transactions-list',
    component: () => import('@/pagsmile/transactions/TransactionsListView.vue'),
    meta: {
      resource: 'Transaction.List',
    },
  },
  {
    path: '/transactions/detail',
    name: 'transactions-detail',
    component: () => import('@/pagsmile/transactions/TransactionsDetailView.vue'),
    meta: {
      layout: 'full',
      resource: 'Transaction.List',
    },
  },
  {
    path: '/transactions/download',
    name: 'transactions-download-log',
    component: () => import('@/pagsmile/transactions/TransactionsDownloadView.vue'),
    meta: {
      resource: 'Transaction.Download',
    },
  },
  {
    path: '/transactions/refund',
    name: 'transactions-refund',
    component: () => import('@/pagsmile/transactions/RefundTransactionsListView.vue'),
    meta: {
      resource: 'Transaction.Refund',
    },
  },
  {
    path: '/waybill/list',
    name: 'waybill-list',
    component: () => import('@/pagsmile/waybill/WaybillListView.vue'),
    meta: {
      resource: 'Waybill.List',
      pageTitle: 'Waybill',
      breadcrumb: [
        {
          text: 'Waybill',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/waybill/upload',
    name: 'waybill-upload',
    component: () => import('@/pagsmile/waybill/WaybillUploadView.vue'),
    meta: {
      resource: 'Waybill.Upload',
      pageTitle: 'Waybill',
      breadcrumb: [
        {
          text: 'Waybill',
        },
        {
          text: 'Upload',
          active: true,
        },
      ],
    },
  },
  {
    path: '/waybill/download',
    name: 'waybill-download',
    component: () => import('@/pagsmile/waybill/WaybillDownloadView.vue'),
    meta: {
      resource: 'Waybill.Download',
      pageTitle: 'Waybill',
      breadcrumb: [
        {
          text: 'Waybill',
        },
        {
          text: 'Download',
          active: true,
        },
      ],
    },
  },
  {
    path: '/registration/list',
    name: 'registration',
    component: () => import('@/pagsmile/commercial/registration/RegistrationListView.vue'),
    meta: {
      resource: 'Registration',
      pageTitle: 'Registration',
      breadcrumb: [
        {
          text: 'Registration',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/recurring/list',
    name: 'recurring-list',
    component: () => import('@/pagsmile/recurring/RecurringListView.vue'),
    meta: {
      resource: 'Recurring.List',
    },
  },

  // commercial
  {
    path: '/merchant/list',
    name: 'merchant-list',
    component: () => import('@/pagsmile/commercial/merchants/MerchantListView.vue'),
    meta: {
      resource: 'Merchants.List',
      pageTitle: 'Merchants',
      breadcrumb: [
        {
          text: 'Commercial',
        },
      ],
    },
  },
  {
    path: '/virtual-account/list',
    name: 'virtual-account-list',
    component: () => import('@/pagsmile/commercial/virtualaccs/VirtualAccountListView.vue'),
    meta: {
      resource: 'VirtualAccount.List',
      pageTitle: 'VirtualAccount',
      breadcrumb: [
        {
          text: 'Commercial',
        },
      ],
    },
  },
  {
    path: '/virtual-account/transactions',
    name: 'virtual-account-transactions',
    component: () => import('@/pagsmile/commercial/virtualaccs/VirtualAccountTransactionListView.vue'),
    meta: {
      resource: 'VirtualAccount.List',
      pageTitle: 'VirtualAccountTransactions',
      breadcrumb: [
        {
          text: 'Commercial',
        },
      ],
    },
  },
  {
    path: '/virtual-account/download-list',
    name: 'virtual-account-transactions-download-list',
    component: () => import('@/pagsmile/commercial/virtualaccs/VirtualAccountTransactionDownloadView.vue'),
    meta: {
      resource: 'VirtualAccount.List',
      pageTitle: 'VirtualAccountTransactions',
      breadcrumb: [
        {
          text: 'Commercial',
        },
      ],
    },
  },
  {
    path: '/merchant/app-list',
    name: 'app-list',
    component: () => import('@/pagsmile/commercial/merchants/app/AppListView.vue'),
    meta: {
      resource: 'Merchants.AppList',
      pageTitle: 'AppList',
      breadcrumb: [
        {
          text: 'app',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/app-download-list',
    name: 'app-download-list',
    component: () => import('@/pagsmile/commercial/merchants/app/AppDownloadView.vue'),
    meta: {
      resource: 'Merchants.AppDownload',
      pageTitle: 'AppDownload',
      breadcrumb: [
        {
          text: 'app',
        },
        {
          text: 'Download',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/payout-account',
    name: 'payout-account',
    component: () => import('@/pagsmile/commercial/merchants/PayoutAccountList.vue'),
    meta: {
      resource: 'Merchants.List',
      navActiveLink: 'merchant-list',
      pageTitle: 'Payout Account',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Merchant',
          replacement: 'merchant_name',
        },
      ],
    },
  },
  {
    path: '/merchant/virtual-account-range',
    name: 'virtual-account-range',
    component: () => import('@/pagsmile/commercial/merchants/TransferAccountRangeList.vue'),
    meta: {
      resource: 'Merchants.List',
      navActiveLink: 'merchant-list',
      pageTitle: 'Virtual Account',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Merchant',
          replacement: 'merchant_name',
        },
      ],
    },
  },
  {
    path: '/merchant/fee-config',
    name: 'merchant-fee-config',
    component: () => import('@/pagsmile/commercial/merchants/MerchantFeeConfigView.vue'),
    meta: {
      resource: 'Merchants.List',
      navActiveLink: 'merchant-list',
      pageTitle: 'Merchants',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Merchants',
          replacement: 'merchant_name',
        },
      ],
    },
  },
  {
    path: '/merchant/users',
    name: 'merchant-users',
    component: () => import('@/pagsmile/commercial/merchants/MerchantUsersView.vue'),
    meta: {
      resource: 'Merchants.List',
      navActiveLink: 'merchant-list',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Merchants',
          replacement: 'merchant_name',
        },
      ],
    },
  },
  {
    path: '/merchant/apps',
    name: 'merchant-apps',
    component: () => import('@/pagsmile/commercial/merchants/AppListView.vue'),
    meta: {
      resource: 'Merchants.List',
      navActiveLink: 'merchant-list',
      pageTitle: 'APPs',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Merchants',
          replacement: 'merchant_name',
        },
      ],
    },
  },
  {
    path: '/merchant/app/risk-configs',
    name: 'merchant-app-risk-configs',
    component: () => import('@/pagsmile/commercial/merchants/AppRiskConfigView.vue'),
    meta: {
      resource: 'Merchants.List',
      navActiveLink: 'merchant-list',
      pageTitle: 'RiskControl Rule Configuration',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Merchants',
          replacement: 'merchant_name',
        },
        {
          text: 'APP',
          replacement: 'app_name',
        },
      ],
    },
  },
  {
    path: '/merchant/app/configs',
    name: 'merchant-app-configs',
    component: () => import('@/pagsmile/commercial/merchants/AppConfigView.vue'),
    meta: {
      resource: 'Merchants.List',
      navActiveLink: 'merchant-list',
      pageTitle: 'APP Configuration',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Merchants',
          replacement: 'merchant_name',
        },
        {
          text: 'APP',
          replacement: 'app_name',
        },
      ],
    },
  },
  {
    path: '/merchant/app/channels',
    name: 'merchant-app-channels',
    component: () => import('@/pagsmile/commercial/merchants/AppChannelListView.vue'),
    meta: {
      resource: 'Merchants.List',
      navActiveLink: 'merchant-list',
      pageTitle: 'APP Channels',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Merchants',
          replacement: 'merchant_name',
        },
        {
          text: 'APP',
          replacement: 'app_name',
        },
      ],
    },
  },
  {
    path: '/merchant/app/channel/fees',
    name: 'merchant-app-channel-fees',
    component: () => import('@/pagsmile/commercial/merchants/AppChannelFeeListView.vue'),
    meta: {
      resource: 'Merchants.List',
      navActiveLink: 'merchant-list',
      pageTitle: 'APP Channel Fees',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Merchants',
          replacement: 'merchant_name',
        },
        {
          text: 'APP',
          replacement: 'app_name',
        },
        {
          text: 'Channel',
          replacement: 'channel_name',
        },
      ],
    },
  },
  {
    path: '/merchant/kyc-list',
    name: 'kyc-list',
    component: () => import('@/pagsmile/commercial/merchants/KycAdminList.vue'),
    meta: {
      resource: 'Merchants.Kyc',
    },
  },
  {
    path: '/merchant/kyc-detail',
    name: 'kyc-detail',
    component: () => import('@/pagsmile/commercial/merchants/KycAdminDetail.vue'),
    meta: {
      resource: 'Merchants.Kyc',
      layout: 'full',
    },
  },
  {
    path: '/channel/list',
    name: 'channel-list',
    component: () => import('@/pagsmile/commercial/channels/ChannelListView.vue'),
    meta: {
      resource: 'Channels.List',
      pageTitle: 'Channels',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Channels',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/channel/group',
    name: 'channel-group',
    component: () => import('@/pagsmile/commercial/channels/ChannelGroupView.vue'),
    meta: {
      resource: 'Channels.Group',
      pageTitle: 'Channels',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Channels',
        },
        {
          text: 'Group',
          active: true,
        },
      ],
    },
  },
  {
    path: '/channel/group/:group_id',
    name: 'channel-group-weights',
    component: () => import('@/pagsmile/commercial/channels/ChannelGroupWeights.vue'),
    meta: {
      resource: 'Channels.List',
      navActiveLink: 'channel-group',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Channels',
        },
        {
          text: 'Group',
        },
        {
          text: 'Weights',
          replacement: 'group_name',
          active: true,
        },
      ],
    },
  },
  {
    path: '/channel/fees/:cid',
    name: 'channel-fees',
    component: () => import('@/pagsmile/commercial/channels/ChannelFeeListView.vue'),
    meta: {
      resource: 'Channels.List',
      navActiveLink: 'channel-list',
      pageTitle: 'Channels',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Channel',
        },
        {
          text: 'Fees',
          active: true,
        },
      ],
    },
  },
  {
    path: '/channel/management',
    name: 'channel-management',
    component: () => import('@/pagsmile/commercial/channels/ChannelManagementView.vue'),
    meta: {
      resource: 'Channels.Management',
      pageTitle: 'Channels',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Channels',
        },
        {
          text: 'Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/channel/switching-channels',
    name: 'switching-channels',
    component: () => import('@/pagsmile/commercial/channels/SwitchingChannels.vue'),
    meta: {
      resource: 'Channels.Swither',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Channels',
        },
        {
          text: 'Monitor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promotions',
    name: 'promotions',
    component: () => import('@/pagsmile/commercial/promotions/PromotionListView.vue'),
    meta: {
      resource: 'Promotions',
      pageTitle: 'Promotions',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Promotions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promotion/wizard',
    name: 'promotion-wizard',
    component: () => import('@/pagsmile/commercial/promotions/NewPromotionWizard.vue'),
    meta: {
      resource: 'Promotions',
      pageTitle: 'Promotions',
      navActiveLink: 'promotions',
      breadcrumb: [
        {
          text: 'Commercial',
        },
        {
          text: 'Promotions',
        },
        {
          text: 'New',
          active: true,
        },
      ],
    },
  },
  // financial
  {
    path: '/settlement/manual',
    name: 'settlement-manual',
    component: () => import('@/pagsmile/financial/settlement/SettlementManualView.vue'),
    meta: {
      resource: 'Settlement.Manual',
    },
  },
  {
    path: '/settlement/report',
    name: 'settlement-report',
    component: () => import('@/pagsmile/financial/settlement/SettlementReportView.vue'),
    meta: {
      resource: 'Settlement.Report',
    },
  },
  {
    path: '/settlement/report/salesstatement-detail/:id',
    name: 'salesstatement-detail',
    component: () => import('@/pagsmile/financial/settlement/SalesStatementDetailView.vue'),
    meta: {
      resource: 'Settlement.Report',
      layout: 'full',
    },
  },
  {
    path: '/settlement/report/settlement-detail/:id',
    name: 'settlement-detail',
    component: () => import('@/pagsmile/financial/settlement/SettlementDetailView.vue'),
    meta: {
      resource: 'Settlement.Report',
      layout: 'full',
    },
  },
  {
    path: '/settlement/invoice',
    name: 'settlement-invoice',
    component: () => import('@/pagsmile/financial/settlement/SettlementInvoiceView.vue'),
    meta: {
      resource: 'Settlement.Invoice',
    },
  },
  {
    path: '/settlement/payout',
    name: 'settlement-payout',
    component: () => import('@/pagsmile/financial/settlement/SettlementPayoutView.vue'),
    meta: {
      resource: 'Settlement.Payout',
    },
  },
  {
    path: '/settlement/payout/download',
    name: 'settlement-payout-download',
    component: () => import('@/pagsmile/financial/settlement/SettlementPayoutDownloadView.vue'),
    meta: {
      resource: 'Settlement.Payout',
    },
  },
  {
    path: '/payments/list',
    name: 'payments-list',
    component: () => import('@/pagsmile/financial/payments/PaymentsListView.vue'),
    meta: {
      resource: 'Payments.List',
    },
  },
  {
    path: '/payment/detail',
    name: 'payment-detail',
    component: () => import('@/pagsmile/financial/payments/PaymentDetailView.vue'),
    meta: {
      layout: 'full',
      resource: 'Payments.List',
    },
  },
  {
    path: '/payments/download',
    name: 'payments-download',
    component: () => import('@/pagsmile/financial/payments/PaymentsDownloadView.vue'),
    meta: {
      resource: 'Payments.Download',
    },
  },
  {
    path: '/bankbills/query',
    name: 'bank-bills-query',
    component: () => import('@/pagsmile/financial/bankbills/BankBillsQueryView.vue'),
    meta: {
      resource: 'BankBills.Query',
    },
  },
  {
    path: '/bankbills/download',
    name: 'bank-bills-download',
    component: () => import('@/pagsmile/financial/bankbills/BankBillsDownloadView.vue'),
    meta: {
      resource: 'BankBills.Download',
    },
  },
  {
    path: '/bankbills/upload',
    name: 'bank-bills-upload',
    component: () => import('@/pagsmile/financial/bankbills/BankBillsUploadView.vue'),
    meta: {
      resource: 'BankBills.Download',
    },
  },

  // anti-fraud
  {
    path: '/anti-fraud/anonymous',
    name: 'anonymous',
    component: () => import('@/pagsmile/anti-fraud/AnonymousView.vue'),
    meta: {
      resource: 'Anonymous',
    },
  },
  // {
  //   path: '/anti-fraud/rule-management',
  //   name: 'af-rule-management',
  //   component: () => import('@/pagsmile/anti-fraud/RuleManagementView.vue'),
  //   meta: {
  //     resource: 'AF.RuleManagement',
  //   },
  // },
  // {
  //   path: '/anti-fraud/tongdun/policy',
  //   name: 'td-policy',
  //   component: () => import('@/pagsmile/anti-fraud/TongDunPolicy.vue'),
  //
  //   meta: {
  //     resource: 'AF.TongDun.Policy',
  //   },
  // },
  // {
  //   path: '/anti-fraud/tongdun/invoke',
  //   name: 'td-invoke',
  //   component: () => import('@/pagsmile/anti-fraud/TongDunInvoke.vue'),
  //   meta: {
  //     resource: 'AF.TongDun.Invoke',
  //   },
  // },
  // {
  //   path: '/anti-fraud/tongdun/risk-trend',
  //   name: 'td-risk-trend',
  //   component: () => import('@/pagsmile/anti-fraud/TongDunRiskTrend.vue'),
  //   meta: {
  //     resource: 'AF.TongDun.RiskTrend',
  //   },
  // },

  // anti-money-laundry
  {
    path: '/aml/id-management/list',
    name: 'aml-id-list',
    component: () => import('@/pagsmile/anti-money-laundry/AmlIdListView.vue'),
    meta: {
      resource: 'AML.ID.List',
    },
  },
  {
    path: '/aml/id-management/detail',
    name: 'id-detail',
    component: () => import('@/pagsmile/anti-money-laundry/IdDetailView.vue'),
    meta: {
      layout: 'full',
      resource: 'AML.ID.List',
    },
  },
  {
    path: '/aml/id-management/blacklist',
    name: 'aml-id-blacklist',
    component: () => import('@/pagsmile/anti-money-laundry/AmlBlackListView.vue'),
    meta: {
      resource: 'AML.ID.BlackList',
    },
  },
  {
    path: '/aml/id-management/blackdetail',
    name: 'aml-black-detail',
    component: () => import('@/pagsmile/anti-money-laundry/AmlBlackDetailView.vue'),
    meta: {
      layout: 'full',
      resource: 'AML.ID.BlackList',
    },
  },
  {
    path: '/aml/id-management/download',
    name: 'aml-id-download',
    component: () => import('@/pagsmile/anti-money-laundry/AmlDownloadView.vue'),
    meta: {
      resource: 'AML.ID.Download',
    },
  },
  // {
  //   path: '/aml/transactions-review',
  //   name: 'aml-transactions-review',
  //   component: () => import('@/pagsmile/anti-money-laundry/TransactionsReviewList.vue'),
  //   meta: {
  //     resource: 'AML.Review.List',
  //   },
  // },
  // {
  //   path: '/aml/download',
  //   name: 'review-transactions-download-log',
  //   component: () => import('@/pagsmile/anti-money-laundry/AmlReviewDownloadView.vue'),
  //   meta: {
  //     resource: 'AML.Review.Download',
  //   },
  // },
  // {
  //   path: '/aml/transaction-detail',
  //   name: 'aml-transaction-detail',
  //   component: () => import('@/pagsmile/anti-money-laundry/TransactionsReviewDetail.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'AML.Review.List',
  //   },
  // },
  {
    path: '/aml/rules/list',
    name: 'aml-rules',
    component: () => import('@/pagsmile/anti-money-laundry/RuleManagementList.vue'),
    meta: {
      resource: 'AML.Rules.List',
    },
  },
  {
    path: '/aml/rules/group',
    name: 'aml-rule-group',
    component: () => import('@/pagsmile/anti-money-laundry/RuleGroupManagement.vue'),
    //
    meta: {
      resource: 'AML.Rules.Group',
    },
  },
  {
    path: '/aml/sanctions',
    name: 'sanctions',
    component: () => import('@/pagsmile/anti-fraud/SanctionsView.vue'),
    meta: {
      resource: 'Sanctions',
    },
  },
  {
    path: '/aml/training',
    name: 'training',
    component: () => import('@/pagsmile/anti-fraud/TrainingView.vue'),
    meta: {
      resource: 'Training',
    },
  },

  // reseller
  {
    path: '/reseller/users',
    name: 'reseller-users',
    component: () => import('@/pagsmile/reseller/users/UserListView.vue'),
    meta: {
      resource: 'Reseller',
    },
  },
  // {
  //   path: '/reseller/reseller-management',
  //   name: 'reseller-management',
  //   component: () => import('@/pagsmile/reseller/resellerManagement/Index.vue'),
  //   meta: {
  //     resource: 'Reseller.Management',
  //   },
  // },
  {
    path: '/reseller/kyc-management',
    name: 'kyc-management',
    component: () => import('@/pagsmile/reseller/kycManagement/Index.vue'),
    meta: {
      resource: 'Reseller.KYC',
    },
  },
  {
    path: '/reseller/info/detail',
    name: 'reseller-info-detail',
    component: () => import('@/pagsmile/reseller/resellerManagement/ResellerInfoDetail.vue'),
    meta: {
      resource: 'Auth',
      layout: 'full',
    },
  },

  // user
  {
    path: '/user/list',
    name: 'user-list',
    component: () => import('@/pagsmile/user/UserListView.vue'),
    meta: {
      resource: 'Admin',
    },
  },

  // development
  {
    path: '/development/calendar',
    name: 'calendar',
    component: () => import('@/pagsmile/development/calendar/Calendar.vue'),
    meta: {
      resource: 'Calendar',
    },
  },
  {
    path: '/development/v1-import',
    name: 'v1-import',
    component: () => import('@/pagsmile/development/V1ImportView.vue'),
    meta: {
      resource: 'V1',
    },
  },
  {
    path: '/development/backdoor',
    name: 'backdoor',
    component: () => import('@/pagsmile/development/BackdoorView.vue'),
    meta: {
      resource: 'Backdoor',
    },
  },
]
