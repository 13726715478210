<template>
  <div v-if="isShow"
       class="loading-wrap"
  >
    <div class="app-toast">
      <!-- <img src="./loading.gif"
           alt=""> -->
      <b-spinner style="width: 4.5rem; height: 4.5rem;"
                 class="ml-2"
                 label="Large Spinner"
      />
    </div>
  </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
}
</script>

<style lang="scss" scoped>
.loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  // background: transparent;
  background-color: rgba(51, 51, 51, 0.7);
  .app-toast {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 50%;
    top: 50%;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    color: #fff;
    img {
      padding: 1rem;
      width: 8rem;
      height: 8rem;
    }
  }
}
</style>
